



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import dayjs from 'dayjs';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import CallCenterCallService from '@/services/crm/call-center-call.service';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';

type clientHistoryInformationCall = {
  dateInitial: string;
  clientName: string | undefined;
  contactNumber: string | undefined;
  contactName: string | undefined;
  callType: CallCenterCallTypeEnum | undefined;
  totalCallTime: string | undefined;
};

@Component({
  components: {},
})
export default class CrmCallCenterHistoryCall extends Vue {
  @inject(InjectionIdEnum.CallCenterCallService)
  private callCenterCallService!: CallCenterCallService;

  @Prop({ required: true })
  call!: CallCenterCallModel;

  callHistory: CallCenterCallModel[] = [];

  clientHistoryInformation: clientHistoryInformationCall | null = null;

  async created(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      if (this.call.protocolo === '') {
        throw new Error('Protocolo da chamada não identificado.');
      }

      this.callHistory = await this.callCenterCallService.getAllByProtocol(this.call.protocolo);
      this.evaluateData();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  protected getTimeCurrentCall(call: CallCenterCallModel): string {
    if (this.call) {
      const startCall = dayjs(call.dataAtendimento || call.dataInclusao);
      const endCall = dayjs(call.dataFinalizacao || call.dataTransferencia);

      return `${startCall.format('HH:mm')} - ${endCall.format('HH:mm')}`;
    }

    return '';
  }

  private evaluateData(): void {
    if (this.callHistory.length > 0) {
      const call = this.callHistory[0];
      if (call) {
        this.clientHistoryInformation = {
          dateInitial: dayjs(call.dataInclusao).format('DD/MM/YYYY'),
          clientName: this.getClientName(call),
          contactNumber: call.numeroContato,
          callType: call.tipoLigacao,
          contactName: call.contato ? call.contato.nome : '',
          totalCallTime: this.getTotalCallTime(),
        };
      }
    }
  }

  private getClientName(call: CallCenterCallModel): string {
    if (!this.call) {
      return '';
    }

    if (call.tipo === ConversationTypeEnum.Client && call.cliente) {
      return `${call.cliente?.codCliente} - ${call.cliente?.nome || call.cliente?.nomeFantasia}`;
    }

    if (call.tipo === ConversationTypeEnum.Prospect && call.prospect) {
      return `${call.prospect?.codProspect} - ${call.prospect?.razaoSocial}`;
    }

    return '';
  }

  private getTotalCallTime(): string {
    if (this.callHistory.length > 0) {
      const firstCall = this.callHistory[this.callHistory.length - 1];
      const lastCall = this.callHistory[0];
      const startCall = firstCall.dataAtendimento || firstCall.dataInclusao;
      const endCall = lastCall.dataFinalizacao || lastCall.dataTransferencia;

      const seconds = dayjs(endCall).diff(startCall, 'second');
      const date = new Date(0);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(seconds);

      const timeSplit = dayjs(date).format('HH:mm:ss').split(':');
      let formatText = '';
      if (Number(timeSplit[0]) > 0) {
        formatText = this.$t(
          `crm.callCenter.historyCall.totalCallTimeFormat.${Number(timeSplit[0]) > 1 ? 'hours' : 'hour'}`,
          { hour: timeSplit[0] },
        ).toString();
      }

      if (formatText !== '' || Number(timeSplit[1]) > 0) {
        formatText += this.$t(
          `crm.callCenter.historyCall.totalCallTimeFormat.${Number(timeSplit[1]) > 1 ? 'minutes' : 'minute'}`,
          {
            minute: timeSplit[1],
          },
        ).toString();
      }

      if (formatText !== '' || Number(timeSplit[2]) > 0) {
        formatText += this.$t(
          `crm.callCenter.historyCall.totalCallTimeFormat.${Number(timeSplit[2]) > 1 ? 'seconds' : 'second'}`,
          {
            second: timeSplit[2],
          },
        ).toString();
      }

      return formatText;
    }
    return '';
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.callCenterHistoryCallContainer,
      canCancel: false,
    });
  }
}
