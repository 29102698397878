















































































































































































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import {
  SelectionChangedEvent,
  GridReadyEvent,
  ValueGetterParams,
  ValueFormatterParams,
  ICellRendererParams,
} from '@ag-grid-community/core';
import { isArray, sortBy } from 'lodash';
import { plainToClass } from 'class-transformer';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import HistoryTypeModel from '@/models/crm/history-type.model';
import UserModel from '@/models/user.model';
import ActivityService from '@/services/crm/activity.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ContactModel from '@/models/crm/contact.model';
import ContactService from '@/services/crm/contact.service';
import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';
import GroupModel from '@/models/crm/group.model';
import CrmCallCenterHistoryCall from '@/components/crm/call-center-history-call.vue';
import CallCenterCallService from '@/services/crm/call-center-call.service';
import { CallCenterCallStatusEnum } from '@/enums/crm/call-center-call-status.enum';
import CallCenterCallModel from '@/models/crm/call-center-call.model';

type DataGridFilterConfig = {
  client: string[] | null;
  prospect: number[] | null;
  period: (Date | undefined)[];
  resultType: CallCenterCallTypeEnum[] | null;
  callType: CallCenterCallTypeEnum[] | null;
  attendant: number[] | null;
  protocol: number | null;
  legalName: string | null;
  contactName: string | null;
  contactNumber: string | null;
  group: GroupModel[] | null;
  historyType: HistoryTypeModel[] | null;
};

interface ISelectOption<T> {
  code: T;
  description: string;
}

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmCallCenterHistoryCall,
  },
})
export default class CrmCalls extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CallCenterCallService)
  private callCenterCallService!: CallCenterCallService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  private settings!: SettingsModel;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    itemsPerPage: 30,
    columnDefs: [
      GridHelper.getSelectionColDef({ headerCheckboxSelection: false }),
      {
        headerName: `${this.$t('crm.view.calls.grid.startDate')}`,
        colId: 'startDate',
        field: 'dataAtendimento',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataAtendimento || params.data.dataInclusao;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params: ValueFormatterParams): string => {
          if (params.value && params.value !== 'Invalid Date') {
            return DateHelper.formatToLocale(params.value, 'dateTime');
          }
          return '';
        },
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.endDate')}`,
        colId: 'endDate',
        field: 'dataFinalizacao',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataFinalizacao;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params: ValueFormatterParams): string => {
          if (params.value && params.value !== 'Invalid Date') {
            return DateHelper.formatToLocale(params.value, 'dateTime');
          }
          return '';
        },
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.callType')}`,
        colId: 'callType',
        field: 'tipoLigacao',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            const callType = params.data.tipoLigacao || '';

            return this.$t(`crm.view.calls.filter.${callType.toLowerCase()}`).toString();
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.resultType')}`,
        colId: 'resultType',
        field: 'statusConclusao',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const resultType = params.data.statusConclusao || '';
          if (resultType) {
            return this.$t(`crm.view.calls.filter.result_${resultType.toLowerCase()}`).toString();
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.protocol')}`,
        colId: 'protocol',
        field: 'protocolo',
        cellClass: 'cell-grid-link',
        maxWidth: 150,
        sortable: true,
        cellStyle: (params: any): any => {
          let color = '';
          if ([CallCenterCallStatusEnum.NewCall, CallCenterCallStatusEnum.Finish].includes(params.data.statusLigacao)) {
            color = '#1CB2A4';
          }

          if ([CallCenterCallStatusEnum.Transfer].includes(params.data.statusLigacao)) {
            color = '#FF9800';
          }

          return {
            color,
          };
        },
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.attendant')}`,
        colId: 'attendant',
        field: 'atendente.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.contactNumber')}`,
        colId: 'contactNumber',
        field: 'numeroContato',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.legalName')}`,
        colId: 'legalName',
        sortable: false,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            if (params.data.tipo === 'CLIENT') {
              return params.data?.cliente?.nome || params.data?.cliente?.nomeFantasia;
            }
            if (params.data.tipo === 'PROSPECT') {
              return params.data?.prospect?.razaoSocial || params.data?.prospect?.nome;
            }
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.contactName')}`,
        colId: 'contactName',
        field: 'contato.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data?.contato?.nome || params.data.numeroWhatsapp;
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.callReport.grid.group')}`,
        colId: 'group',
        field: 'grupoArea.descricao',
        flex: 0.8,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.historyType')}`,
        colId: 'historyType',
        field: 'tipoHistorico.nome',
        flex: 0.8,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.calls.grid.description')}`,
        colId: 'description',
        field: 'descricao',
        maxWidth: 150,
        sortable: true,
        cellRenderer: (params: ICellRendererParams): string => params.data.descricao,
      },
      {
        headerName: `${this.$t('crm.view.callReport.grid.recordingUrl')}`,
        colId: 'recordingUrl',
        field: 'urlGravacaoChamada',
        flex: 0.4,
        sortable: true,
        // eslint-disable-next-line arrow-body-style
        cellRenderer: (params: ICellRendererParams): string => {
          // eslint-disable-next-line max-len
          return `<a href="${params.data.urlGravacaoChamada}" target="_blank"><span  class="v-icon  mdi mdi-microphone-outline"></span></a>`;
        },
      },
    ],
  };

  items: CallCenterCallModel[] = [];

  selected: CallCenterCallModel[] = [];

  // #region filters

  predefinedPeriodRanges: IDateRangeConfig[] = this.getDateRanges();

  multipleFilterChanged = false;

  filters: DataGridFilterConfig = {
    client: null,
    prospect: null,
    period: [dayjs().startOf('year').toDate(), dayjs().toDate()],
    callType: [CallCenterCallTypeEnum.Active, CallCenterCallTypeEnum.Receptive],
    resultType: [CallCenterCallTypeEnum.Active, CallCenterCallTypeEnum.NotSuccessful],
    attendant: null,
    protocol: null,
    legalName: null,
    contactName: null,
    contactNumber: null,
    group: null,
    historyType: null,
  };

  callTypeOptions: ISelectOption<CallCenterCallTypeEnum>[] = [];

  resultTypeOptions: ISelectOption<CallCenterCallTypeEnum>[] = [];

  attendantOptions: UserModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  groupOptions: GroupModel[] = [];

  // #endregion

  dialogConfig: IKeyValue<IDialogConfig> = {
    history: {
      show: false,
      call: null,
    },
  };

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      this.settings = await this.settingsService.getSettings();
      await this.loadFilterOptions();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }

    if (this.isProspectType) {
      this.filters.prospect = [parseInt(this.getClientIdFromRoute(), 10)];
    } else {
      this.filters.client = [this.getClientIdFromRoute()];
    }

    this.loadItems();
  }

  onFilterChange(type: string): void {
    if (type === 'multiple' && !this.multipleFilterChanged) {
      return;
    }

    this.multipleFilterChanged = false;
    this.loadItems();
  }

  async onCellClick(event: IGridCellEvent<CallCenterCallModel>): Promise<void> {
    if (!event.data || event?.colDef?.colId !== 'protocol') {
      return;
    }

    this.openHistoryCallDialog(plainToClass(CallCenterCallModel, event.data));
  }

  openHistoryCallDialog(call: CallCenterCallModel): void {
    if (!call) {
      return;
    }

    this.dialogConfig.history.show = true;
    this.dialogConfig.history.call = call;
  }

  onCloseHistoryDialogClose(): void {
    this.dialogConfig.history.show = false;
    this.dialogConfig.history.call = false;
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: CallCenterCallModel[]): void {
    this.selected = selected;
  }

  onExport(selected: CallCenterCallModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.items.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'Geovendas',
        sheetName: 'Ligações',
        fileName: CallCenterCallService.generateCallReportExportFilename(new Date()),
      });
    }
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore = ['periodType'];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get viewTitle(): string {
    return this.$t('crm.view.calls.title', { clientType: this.$t(`crm.${this.clientType}`) }).toString();
  }

  get historyDialogTitle(): string {
    const protocol = (this.dialogConfig.history.call as CallCenterCallModel)?.protocolo;
    return this.$t('crm.view.calls.dialog.history.title', { protocol }).toString();
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = (
      await this.callCenterCallService.queryCalls(
        1,
        999,
        this.filters as unknown,
        // orderBy,
      )
    ).results;
    this.gridSettings.loading = false;
  }

  private async loadFilterOptions(): Promise<void> {
    this.callTypeOptions = this.getCallTypeOptions();
    this.resultTypeOptions = this.getResultTypeOptions();

    const tasks = [
      this.getAttendants().then((result) => {
        this.attendantOptions = result;
      }),
      this.getHistoryTypes().then((result) => {
        this.historyTypeOptions = result;
      }),
      this.getGroups().then((result) => {
        this.groupOptions = result;
      }),
    ];

    await Promise.all(tasks);
  }

  private getCallTypeOptions(): ISelectOption<CallCenterCallTypeEnum>[] {
    return [
      {
        code: CallCenterCallTypeEnum.Active,
        description: this.$t('crm.view.callReport.filter.ativa').toString(),
      },
      {
        code: CallCenterCallTypeEnum.Receptive,
        description: this.$t('crm.view.callReport.filter.receptiva').toString(),
      },
    ];
  }

  private getResultTypeOptions(): ISelectOption<CallCenterCallTypeEnum>[] {
    return [
      {
        code: CallCenterCallTypeEnum.Active,
        description: this.$t('crm.view.callReport.filter.result_ativa').toString(),
      },
      {
        code: CallCenterCallTypeEnum.NotSuccessful,
        description: this.$t('crm.view.callReport.filter.result_nao_sucedida').toString(),
      },
      {
        code: CallCenterCallTypeEnum.QuestionMistake,
        description: this.$t('crm.view.callReport.filter.result_engano_duvidas').toString(),
      },
    ];
  }

  private async getAttendants(): Promise<UserModel[]> {
    return this.activityService.getAttendants();
  }

  private async getGroups(): Promise<GroupModel[]> {
    return sortBy(await this.activityService.getGroups(), 'descricao');
  }

  private async getContacts(): Promise<ContactModel[]> {
    return this.contactService.getContacts(
      this.getClientIdFromRoute(),
      this.clientType,
      this.settings.flagPermiteClientesSituacao99Crm360,
    );
  }

  private async getHistoryTypes(): Promise<HistoryTypeModel[]> {
    return sortBy(await this.activityService.getHistoryTypes(), 'nome');
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.today')}`,
        ...DateHelper.getTodayPeriod(),
      },
      {
        name: `${this.$t('global.yesterday')}`,
        ...DateHelper.getYesterdayPeriod(),
      },
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }

  private getClientIdFromRoute(): string {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }
}
